h1,
h2,
h3,
h4,
h5,
h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
}
p {
    @include font-size('p');
    font-family: 'Playfair-regular--p';
    font-weight: normal;
    color: color('brown');
    padding-bottom: 20px;
    line-height: 36px;
}

h1 {
    @include font-size('h1');
    font-family: 'Playfair-regular--h1';
    font-weight: normal;
    color: color('green');
}

h2 {
    @include font-size('h2');
    font-family: 'Playfair-italic--h2';
    font-weight: normal;
    color: color('brown');
}

h3 {
    @include font-size('h3');
    font-family: 'Playfair-bold--h3';
    font-weight: normal;
    color: color('brown');
}

h4 {
    @include font-size('h4');
    font-family: 'Playfair-bold--h4';
    font-weight: normal;
    color: color('light-brown');
}

h5 {
    @include font-size('h5');
    font-family: 'Playfair-bold--h5';
    font-weight: normal;
    color: color('brown');
}

h6 {
    @include font-size('h6');
    font-family: 'Playfair-bold--h6';
    font-weight: normal;
    color: color('green');

}

a {
    color: color('green');

    &:hover {
        color: color('brown');
    }
}
