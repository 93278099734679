.c-content--main {
    .c-content__header {
        width: 85%;
        display: block;
        margin: 0px auto;
        margin-bottom: rem(60px);
        @include media(xs) {
            width: 30%;
        }
    }
    h1 {
        text-align: center;
        text-transform: capitalize;
    }
    h2 {
        padding-bottom: rem(40px);
    }
    h3, h6 {
        padding-bottom: rem(10px);
    }
    .c-btn {
        display: table;
        margin: 0px auto;
        margin-top: rem(20px);
    }
}
// gallery page

.page-id-28 {
    .gallery-item {
        display: inline-block;
        width: 24%;
        padding: rem(5px);
        img {
            -webkit-transition: -webkit-transform 1.5s ease-in-out;
            transition:transform 1.5s ease-in-out;
            &:hover {
                -moz-transform: rotatey(360deg);
                -webkit-transform: rotatey(360deg);
                -o-transform: rotatey(360deg);
                -ms-transform: rotatey(360deg);
                transform: rotatey(360deg);
            }
        }
    }
}

// reservation sPage
.page-id-32 {
    .o-reserve {
        display: none;
    }
}

// rates page border colors

.tablepress tbody td, .tablepress tfoot th {
    border-top: 1px solid color('light-brown');
}
