///
// Custom fonts
///

// Example
// @font-face {
//  font-family: 'questrialregular';
//  src: url('../../dist/fonts/questrial-regular-webfont.woff2') format('woff2'),
//       url('../../dist/fonts/questrial-regular-webfont.woff') format('woff');
//  font-weight: normal;
//  font-style: normal;
// }
//P tags
@font-face {
    font-family: 'Playfair-regular--p';
    src: url('../../dist/fonts/PlayfairDisplay-Regular.ttf');
}
// h1
@font-face {
    font-family: 'Playfair-regular--h1';
    src: url('../../dist/fonts/PlayfairDisplay-Regular.ttf');

}
// H2 tags
@font-face {
    font-family: 'Playfair-italic--h2';
    src: url('../../dist/fonts/PlayfairDisplay-Italic.ttf');

}
//H3 tags
@font-face {
    font-family: 'Playfair-bold--h3';
    src: url('../../dist/fonts/PlayfairDisplay-Bold.ttf');
}
//H4 tags
@font-face {
    font-family: 'Playfair-bold--h4';
    src: url('../../dist/fonts/PlayfairDisplay-Bold.ttf');

}
//H5 tags
@font-face {
    font-family: 'Playfair-bold--h5';
    src: url('../../dist/fonts/PlayfairDisplay-Bold.ttf');

}
//H6 tags
@font-face {
    font-family: 'Playfair-bold--h6';
    src: url('../../dist/fonts/PlayfairDisplay-Bold.ttf');
}
