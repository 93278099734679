.c-activities {
    margin-top: -30px;
    @include media(md) {
        margin-top: 0px;
        }

}
// activities section controls
.c-activities__offers {
    padding: rem(20px);
    background-size: cover;
    background-repeat: no-repeat;
    @include media(md) {
        padding: rem(25px);
    }
    @include media(lg) {
        padding: rem(58px);
    }
    p {
        font-size: rem(24px);
        line-height: rem(36px);
        padding-bottom: 20px;
        text-align: center;
        color: color('white');
    }
    // places button on the center of the container
    .c-btn {
        display: table;
        margin: 0px auto;
    }
}
// background images for each button
.c-activities__offers--activities {
    background-image: url(../../dist/images/activities_back.jpg);
}

.c-activities__offers--amenities {
    background-image: url(../../dist/images/amenities_back.jpg);
}
