// Primary Navigation
.c-nav {
    background-color: color('green');
    width: 100%;
    margin-top: rem(400px);
    height: rem(60px);
    display: none;
    @include media(mdd) {
        display: block;
    }
    .c-header__border {
        height: auto;
        position: relative;
        top: rem(8px);
        width: 100%;
        display: block;
        margin: 0px auto;
        padding: rem(5px) 0px;
        border-top: rem(2px) solid color('grey');
        border-bottom: rem(2px) solid color('grey');
    }
    .c-nav__list {
        width: 100;
        display: block;
        margin: 0 auto;
        list-style: none;
        text-align: center;
        li {
            display: inline;
            padding: 20px 20px;
            &:hover {
                color: color('light-brown');
            }
            a {
                text-decoration: none;
                color: color('white');
                font-size: rem(20px);
                letter-spacing: 1px;
                font-weight: bold;
                text-transform: uppercase;
                &:hover {
                    color: color('light-brown');
                }
            }// a
        } // li
    } //c-nav__list
} //c-nav

//sub menu
.c-nav__list {
    .sub-menu {
        display: none;
        position: absolute;
        text-align: left;
        left: 47.5%;
        width: rem(200px);
        padding-right: rem(10px);
        margin-top: rem(13px);
        background-color: color('green');
        padding-bottom: rem(20px);
        li {
            display: block;
            width: 100%;
            padding: 0px;
            padding-left: 10px;
        }
    }
    li:hover {
        .sub-menu {
            display: block !important;
        }
    }
}


//Mobile Menu
.c-header__mobile {
    display: none;
    position: fixed;
    z-index: 101;
    top: rem(0px);
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: color('green');
    .c-mobile__nav {
        text-align: left;
        padding-left: rem(20px);
        line-height: rem(40px);
        position: relative;
        top: rem(180px);
        list-style: none;
        li {
            line-height: 36px;
            text-transform: uppercase;
        }
        .sub-menu {
            list-style: none;
            padding-left: rem(25px);
            display: none;
        }
    }
    a {
        color: color('white');
        text-decoration: none;
        font-size: rem(20px);
        line-height: rem(5px);
    }
    .c-header__list {
        position: relative;
        margin-top: rem(180px);
        margin-left: rem(60px);
        li {
            display: block;
            padding-bottom: rem(15px);
        }
        .c-btn {
            margin-left: -5px;
        }
    }
}



// Primary navigation Drop Menu on click Toggle
.tochild {
    color: color('white');
    font-size: rem(30px);
    font-weight: bolder;
    position: absolute;
    z-index: 100;
    display: inline;
    top: rem(30px);
    margin-left: neg(rem(10px));
    cursor: pointer;
    left: rem(200px);
    @include media(mdd) {
        float: none;
        font-size: rem(16px);
        margin-right: 0;
        top: rem(5px);
        left: inherit;
    }
}
