.c-footer {
    background-image: url(../../dist/images/bg-wood.jpg);
    background-repeat: repeat;
    padding-top: rem(80px);
    padding-bottom: rem(80px);
    background-attachment: fixed;
    // remove list dots
    ul {
        list-style: none;
    }
    // font controls
    h1 {
        font-size: rem(32px);
    }
    h1, p, a {
        color: color('white');
        text-decoration: none;
        text-align: center;
        display: block;
        margin: 0px auto;
        @include media(xs) {
            text-align: left;
            display: block;
            margin:0px;
        }
    }
    //font sizes for these only
    p, a {
        font-size: rem(20px);
        text-align: center !important;
        @include media(xs) {
            font-size: rem(16px);
            text-align: left !important;
        }

    }
    a:hover {
        color: color('light-brown');
    }
    // footer menu list
    .c-footer__navigation {
        display: none;
        @include media(sm) {
            display: block;
        }
    }
    //footer contact area
    .c-footer__contact {
        p {
            line-height:36px;
            padding-bottom: 5px;
            margin-bottom: 0px;
        }
    }
    // footer sponsors list controls
    .c-footer__sponsors {
        ul {
            display: table;
            margin: 0px auto;
            position: relative;
            width: 220px;
            margin-top: -70px;
            @include media(sm) {
                margin-top: 0px;
                width: 600px;
            }
            li {
                display: inline-block;
                width: 100%;
                @include media(xs) {
                    width: 33%;
                }
                img {
                    width: 100%;
                    display: block;
                    margin: 0px auto;
                }
            }
        }
        a, p {
            font-size: rem(14px);
            text-align: center !important;
        }
        a {
            display: inline;
        }
    }
    iframe {
        margin-top: 20px;
        @include media(xs) {
            margin-top: 0px;
        }
    }
    .tochild {
        display: none;
    }
}
