// tesimonials section background control
.c-testimonials {
    background-image: url(../../dist/images/testimonials_back.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
// testimonials content control
.c-testimonials__content {
    margin-top: 80px;
    h1, p {
        color: color('white');
        display: block;
        margin: 0px auto;
        width: 100%;
        line-height: rem(40px);
        text-align: center;
        @include media(md) {
            width: 80%;
        }
    }
    h1 {
        font-size: 40px;
    }
    p {
        font-size: rem(22px);
        font-style: italic;
        font-family: 'Playfair-regular--p';
        font-weight: 100;
        @include media(md) {
            font-size: rem(28px);
        }
    }
    hr {
        width: 80%;
        display: block;
        margin: rem(20px) auto;
        @include media(mdd) {
            width: 30%;
        }
    }
    cite {
        color: color('white');
        font-family: 'Playfair-regular--p';
        display: table;
        font-style: normal;
        margin: 0px auto;
    }
    // middle allign the button
    .c-btn {
        display: table;
        margin: 0px auto;
    }
}
// controll for the active dots
.c-carousel {
    .owl-dot {
        display: inline-block;
        span {
            display: block;
            width: rem(17px);
            height: rem(17px);
            margin: .1875rem;
            background-color: color('white');
            border-radius: 100%;
        }
    }
    .owl-dot.active  {
        span{
            background-color: color('light-brown');
        }
    }
    .owl-dots {
        margin-top: 1.875rem;
        text-align: center;
    }
}
