body {
    margin: 0;
    background-image: url(../../dist/images/bg-main.jpg);
    background-repeat: repeat;
    background-attachment: fixed;
    font-size: $base-font-size;
    font-family: 'Playfair-regular--p';
    line-height: $base-line-height;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: inherit;
    overflow-x: hidden;
}

// removes that padding added to most classes this makes things allign incorrectly so i had to set all values back to 0
* {
    -webkit-margin-before: 0em;
    -webkit-margin-after: 0em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start:0px;
}
