.o-content {
    width: 100%;
    height: auto;
    position: relative;
    z-index: 8;
    padding-top: rem(30px);
    padding-bottom: rem(30px);
    .o-col {
        padding-top: rem(0px);
        padding-bottom: rem(20px);
        @include media(md) {
            padding-bottom: rem(40px);
        }
    }
}
.c-content--main {
    margin-top: neg(rem(20px));
    padding: rem(20px) rem(0px);
    @include media(md) {
        padding: rem(80px) rem(0px);
        margin-top: neg(rem(40px));
    }
}
