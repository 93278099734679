img {
    max-width: 100%;
    width: 100%;
    height: auto;
    vertical-align: middle;
}

figure {
    margin: 0;
}

figcaption {
    font-size: em(14px);
    font-style: italic;
}
