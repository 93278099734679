// Weather Styles


// tittle for weather
.c-footer__block__title {
    display: none;
}
// Degress display font controls
.wu-day-current-temp {
    font-size: 24px;
    color: #fff;
    top: -35px;
    left: 90px;
    position: relative;
    small {
        display: none;
    }
}
// weather  LOGO
a.external {
    display: none;
}

.wu-current-conditions .wu-day-title {
    float: left;
    font-family: 'Playfair-regular--p';
    white-space: nowrap;
    width: auto;
}
.wu-icon {
    img {
        width: 60px;
    }
}
.error404 {
.c-nav {
    display: none;
}
.c-content--main {
    padding-top: 300px;
}
}
// wp-admin bar will stay fixed
#wpadminbar {
    position: fixed;
}
