.c-cards {
    height: auto;
    margin-top: rem(40px);

    img {
        display: block;
        margin: 0px auto;
        width: 100%;
    }
    .c-btn {
        padding-top: 10px;
        @include media(xs) {
            padding-top: 20px;
        }
        @include media(mdd) {
            padding-top: 10px;
        }
    }
    span {
        text-align: center;
        z-index: 100;
        position: absolute;
        left: 0;right: 0;top: 0;bottom: 0;
        margin: auto;
        height:50px;
        width: 200px;
        @include media(xs) {
            width: 260px;
            height: 70px;
        }
        @include media(mdd) {
            width: 200px;
            height: 50px;
        }
    }
}
.c-cards {
    .o-col:nth-child(2) {
        .c-btn {
            padding-top: 10px;
            @include media(xs) {
                padding-top: 20px;
            }
            @include media(mdd) {
                padding-top: 10px;
            }
        }
        span {
            width: 150px;
            height: 50px;
            @include media(xs) {
                width: 190px;
                height: 70px;

            }
            @include media(mdd) {
                width: 150px;
                height: 50px;
            }
        }
    }
    .o-col:nth-child(3) {
        .c-btn {
            padding-top: 10px;
            @include media(xs) {
                padding-top: 20px;
            }
            @include media(mdd) {
                padding-top: 10px;
            }
        }
        span {
            width: 130px;
            height: 50px;
            @include media(xs) {
                width: 160px;
                height: 70px;
            }
            @include media(mdd) {
                width: 130px;
                height: 50px;
            }
        }
    }
}
