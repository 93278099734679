// Header Container
.o-header {
    z-index: 10;
    height: rem(60px);
    width: 100%;
    background-image: url(../../dist/images/bg-wood.jpg);
    background-repeat: repeat;
    background-attachment: fixed;
    position: fixed;
    @include media(mdd) {
        position: absolute;
    }

}
//Mobile Menu button Control
.c-header__toggle {
    position: fixed;
    right: rem(20px);
    color: color('white');
    text-decoration: none;
    z-index: 1000;
    margin-top: rem(10px);
    display: block;
    border: 1px solid color('grey');
    padding: 5px 10px;
    @include media(mdd) {
        display: none;
    }
    h2 {
        color: color('white');
    }
}

// Contac info on the right column of the top
.c-header__contact {
    display: none;
    @include media(mdd) {
        display: block;
    }
}
.c-header__list {
    position: relative;
    top: rem(5px);
    list-style: none;
    width: 400px;
    right: rem(50px);
    .c-header__number a {
        font-size: rem(24px);
        color: #fff;
        text-decoration: none;
    }
    li {
        display: inline-block;
        padding-left: rem(10px);
    }
}
// Contac info on the left column of the top
.c-header__weather {
    left: 10%;
    position: relative;
    display: none;
    @include media(mdd) {
        display: block;
    }
    small {
        display: none;
    }
}
