// welcome setion control for backgroun image
.c-welcome {
    background-image: url(../../dist/images/home-welcoming.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}
// welcolme section cotent control
.c-welcome__content {
    margin-top: rem(80px);
    margin-bottom: 0px;
    p {
        font-size: rem(24px);
    }
    h1, p {
        color: color('white');
        display: block;
        margin: 0px auto;
        width: 100%;
        line-height: rem(40px);
        text-align: center;
        @include media(md) {
            width: 80%;
        }
    }
    h1 {
        font-size: rem(40px);
    }
    hr {
        width: 80%;
        display: block;
        margin: rem(20px) auto;
        @include media(mdd) {
            width: 30%;
        }
    }
    // button middle alligned
    .c-btn {
        display: table;
        margin: 0px auto;
    }
}
