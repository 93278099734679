// styles for listed items
.c-list {
    list-style: none;
    padding-left: 20px;
}
.c-list li {
    font-family: 'Playfair-regular--p';
    color: color('brown');
    font-size: rem(20px);
    line-height: rem(40px);
    font-weight:normal;
}
.c-list li:before {
    content: "•";
    color: color('brown');
    position: absolute;
    margin-left: neg(rem(20px));
    font-size: rem(16px);
    margin-top: 0px;
}
