// Website logo styling
.c-logo {
    position: absolute;
    left: 15%;
    max-width: 250px;
    z-index: 102;
    top: neg(rem(10px));
    @media (max-width:1023px) {
        left: 5%;
        width: rem(145px);
    }
}
