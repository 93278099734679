input,
select,
textarea {
    width: 100%;
    padding: rem(10px);
    border: 1px solid color('grey', 'medium');
    border-radius: 0;
}

input[type="submit"] {
    width: auto;
}
