// hearos overflow control due to image min width
.c-content__hero {
    .c-hero {
        height: rem(250px);
        overflow: hidden;
        @include media(xs) {
            height: rem(500px);
        }
    }
    .soliloquy-image {
        min-width: 1000px;
        margin-left: neg(rem(400px)) !important;
        @include media(xs) {
            min-width: 2100px;
            margin-left: neg(rem(800px)) !important;
        }
        @include media(md) {
            margin-left: neg(rem(500px)) !important;
        }
        @include media(lg) {
            margin-left: neg(rem(300px)) !important;
        }
        @include media(xll) {
            min-width: 100%;
            margin-left: neg(rem(0px)) !important;
        }
    }
}
