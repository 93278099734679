.c-btn {
    display: inline-block;
    padding: rem(5px) rem(5px);
    padding-top: 10px;
    font-family: 'Playfair-regular--p';
    cursor: pointer;
    @include media(sm) {
        padding: rem(10px) rem(5px);
        padding-top: 15px;
    }
    @include media(mdd) {
        padding: rem(5px) rem(5px);
        padding-top: 8px;
    }
    a {
        text-decoration: none;
        color: color('white');
        padding: rem(1px) rem(15px);
        border: 1px solid color('grey');
        letter-spacing: rem(2px);
        font-size: rem(20px);
        @include media(xs) {
            font-size: rem(30px);
        }
        @include media(mdd) {
            font-size: rem(20px);
        }
    }
}
.c-btn--green {
    background-color: color('green');
    &:hover {
        background-color: color('brown');
    }
}
.c-btn--brown {
    background-color: color('brown');
    &:hover {
        background-color: color('green');
    }
}
